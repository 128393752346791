<template>
  <section>
    <div
      class="rewards-pattern"
      :class="patternColor"
    />
    <div class="grid-x flex-dir-column align-middle rewards-padding-top">
      <div class="grid-x align-middle">
        <i
          class="rewards-icon"
          :class="loyaltyIcon"
        />
        <div
          v-if="bcomMember"
          class="rewards-tier large"
        >
          {{ bcomMember }}
        </div>
      </div>
      <h3 class="rewards-salutation margin-bottom-xxs">
        Hi, {{ username }}
      </h3>
      <div
        v-if="rewards.tierName"
        class="rewards-stats grid-x margin-bottom-s padding-top-xs"
      >
        <div class="grid-x flex-dir-column align-middle padding-right-xl">
          <div class="stat-title medium">
            Rewards Money
          </div>
          <h3 data-testid="rewards-net-worth">
            ${{ netWorth }}
          </h3>
          <div
            v-if="expiryMoney"
            data-testid="rewards-expiry-money"
            class="small rewards-expiry-money"
          >
            ${{ expiryMoney }} expiring soon!
          </div>
        </div>

        <div class="grid-x flex-dir-column align-middle">
          <div class="stat-title medium">
            Points
          </div>
          <h3 data-testid="rewards-both-points">
            {{ points }}
          </h3>
        </div>
      </div>
      <div
        v-else
        class="grid-x flex-dir-column align-middle"
      >
        <p class="medium margin-bottom-s text-center">
          Earn points on purchases when you <br>become a Loyallist
        </p>
        <Link
          class="button button-small primary margin-bottom-xs"
          :href="Links.loyalty.default.url"
          data-testid="join-star-rewards"
        >
          Become a loyalist
        </Link>
      </div>

      <div
        class="grid-x align-middle large-margin-bottom-m"
        :class="{'small-margin-bottom-m medium-margin-bottom-m': rewards.tierName, 'small-margin-bottom-s medium-margin-bottom-s': !rewards.tierName}"
      >
        <Link
          :href="Links.loyalty.benefits.url"
          class="small"
        >
          View Member Benefits
        </Link>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import Link from '../common/Link';
import Links from '../../constants/Links';
import useLoyalty from '../../composables/useLoyalty';
import useAccount from '../../composables/useAccount';

const {
  rewards, bothPoints, bcomMember, loyaltyIcon,
} = useLoyalty();
const { username } = useAccount();
const patternColor = computed(() => {
  const { tierName } = rewards.value;

  return tierName ? `rewards-${tierName}-pattern` : 'rewards-base-pattern';
});
const netWorth = computed(() => {
  const { balance } = rewards.value;

  return balance.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
});

const points = computed(() => bothPoints.value);
const expiryMoney = computed(() => {
  const { expiringBalance, isRewardExpiring } = rewards.value;

  return isRewardExpiring && expiringBalance ? expiringBalance.toFixed(2) : null;
});
</script>

<style lang="scss" scoped>
  .rewards {
    &-pattern {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 10px;

      .mobile & {
        left: -20px;
        right: -20px;
      }
    }

    &-padding-top {
      padding-top: 50px;

      @include breakpoint(medium down) {
        padding-top: 42px;
      }
    }

    &-base-pattern {
      background-color: $loyalty-1-color;
    }

    &-top-pattern {
      background-color: $black;
    }

    &-topUnlocked-pattern {
      background-color: $loyalty-4-color;
    }

    &-icon {
      margin-right: 4px;
    }

    &-tier {
      text-transform: uppercase;
    }

    &-salutation {
      padding-top: 4px;
    }

    &-stats {
      min-width: 60%;
      justify-content: space-between;
    }

    &-expiry-money {
      color: $sale-color;
      margin-top: 4px;
    }
  }

  .stat {
    &-title {
      margin-bottom: 4px;
    }
  }
</style>
