<template>
  <div class="full-width grid-x align-middle align-justify">
    <ul class="links-wrap grid-x align-middle vertical-separator">
      <RegistryDropdown v-if="isRegistryDropdown">
        bREGISTRY
      </RegistryDropdown>
      <li v-else>
        <Link
          class="link-rail-item small"
          :href="Links.homepage.registry.url"
        >
          bREGISTRY
        </Link>
      </li>
      <li>
        <Link
          class="link-rail-item small"
          :href="Links.store.scheduling.url"
        >
          Shopping Services
        </Link>
      </li>
    </ul>
    <LinkRailMenu>
      <StoreLocatorDropdown />
      <AccountDropdown />
      <InternationalContextLink v-if="mounted && internationalContextEnabled" />
    </LinkRailMenu>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useMounted } from '@vueuse/core';

import Links from '../../constants/Links';
import LinkRailMenu from './LinkRailMenu.common';
import Link from '../common/Link';
import AccountDropdown from './AccountDropdown.bcom';
import InternationalContextLink from './InternationalContextLink.bcom';
import StoreLocatorDropdown from './StoreLocatorDropdown.bcom';
import RegistryDropdown from './RegistryDropdown.common';
import useKillswitches from '../../composables/useKillswitches';

const mounted = useMounted();
const killswitches = useKillswitches();

const isRegistryDropdown = computed(() => !!killswitches.value.registryDropdownEnabled);
const internationalContextEnabled = computed(() => killswitches.value.internationalContextEnabled);
</script>

<style lang="scss" scoped brand="bcom">
@import "../../scss/classes.bcom.scss";
</style>
