<template>
  <Minimal v-if="isMinimal" />

  <template v-else>
    <Desktop v-if="isDesktop" />
    <Mobile v-else-if="isMobile">
      <MediaRail />
    </Mobile>
  </template>
</template>

<script setup>
/* istanbul ignore file */
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import MediaRail from './header-responsive/MediaRail.bcom';
import addWindowPop from '../utils/addWindowPop';

import { GET_IS_DESKTOP_VIEW, GET_IS_MOBILE_VIEW } from '../types/getters';
import Desktop from './header-desktop/Desktop.bcom';
import Mobile from './header-mobile/Mobile.common';

defineProps({ // eslint-disable-line no-undef
  isMinimal: {
    type: Boolean,
    default: false,
  },
});

const Minimal = defineAsyncComponent(() => import(/* webpackChunkName: "Minimal" */ './header-responsive/MinimalHeader.common'));

const store = useStore();
const { hostlink } = store.state.envProps;

const isDesktop = computed(() => store.getters[`navViewTypeStore/${GET_IS_DESKTOP_VIEW}`]);
const isMobile = computed(() => store.getters[`navViewTypeStore/${GET_IS_MOBILE_VIEW}`]);

addWindowPop(hostlink);

</script>
