<template>
  <section class="sign-up">
    <div v-if="creditCardData">
      <h4 class="heavy">
        <span v-if="offerHeading">{{ offerHeading }}</span>
        <span v-else>Apply for a Bloomingdale's Credit Card</span>
      </h4>
      <div class="footer-card-content margin-top-xs">
        <div class="cell credit-card-img margin-right-xs">
          <Picture
            image-url="https://images.bloomingdalesassets.com/is/image/BcomMedia/media/screenshot1_1649967259796.png"
            alt=""
            :using-scene7="false"
          />
        </div>
        <div>
          <div
            v-for="(paragraph, index) in offerParagraphs"
            :key="index"
            class="small"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="index === offerParagraphs.length - 1"
              class="container-with-actions"
            >
              <div v-html="paragraph" />
              <template
                v-for="action in offerActions"
                :key="action.value"
              >
                <Link
                  :href="action.value"
                >
                  {{ action.text }}
                </Link>
              </template>
            </div>
            <div
              v-else
              v-html="paragraph"
            />
            <!-- eslint-enable -->
          </div>
        </div>
      </div>
    </div>
    <Skeleton
      v-else
      :height="'114px'"
    />
  </section>
</template>

<script setup>
/* istanbul ignore file */
import {
  ref, watch, computed, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import Picture from '@atomic-ui/picture';
import Skeleton from '@atomic-ui/skeleton';
import addQueryParam from '../../utils/addQueryParam';

import Link from '../common/Link';
import { GET_CREDIT_CARD_DATA, GET_IS_DESKTOP_VIEW } from '../../types/getters';

const store = useStore();

const isSignedIn = computed(() => !!store.state.navUser.data?.isSignedIn);
const isDesktop = computed(() => store.getters[`navViewTypeStore/${GET_IS_DESKTOP_VIEW}`]);
const creditCardData = computed(() => store.getters[`footerStore/${GET_CREDIT_CARD_DATA}`]);

const offerHeading = ref('');
const offerParagraphs = ref([]);
const offerActions = ref([]);

watch(
  [isSignedIn, isDesktop, creditCardData],
  () => {
    if (!creditCardData.value) return;

    const { heading, content, actions } = creditCardData.value;
    let normalizedActions = actions;

    let scParam;
    let cmpParam;

    if (actions.length) {
      if (isDesktop.value) {
        scParam = '80202'; // Desktop scParam
        cmpParam = isSignedIn.value ? 'A~A~F~3~4~E~ZZK1~AI~BL~ZZ' : 'A~A~F~Z~4~8~ZZZ0~CL~BL~ZZ';
      } else {
        scParam = '80205'; // Mobile scParam
        cmpParam = isSignedIn.value ? 'C~A~F~3~4~E~ZZK1~AI~BL~ZZ' : 'C~A~N~Z~4~8~LZZ0~CL~BL~ZZ';
      }

      const paramsToAdd = { sc: scParam, cmp: cmpParam };
      normalizedActions = actions.map((link, index) => {
        if (index) return link;

        const url = addQueryParam(link.value, paramsToAdd);

        return {
          ...link,
          value: url,
        };
      });
    }

    offerHeading.value = heading;
    offerParagraphs.value = content
      .split(/<\/p>\s*<p>/)
      .map((paragraph) => paragraph.replace(/<[^>]*>/g, ''));
    offerActions.value = actions;

    nextTick(() => {
      offerActions.value = normalizedActions;
    });
  },
  { immediate: true },
);
</script>

<style scoped lang="scss" brand="bcom">
@import "~settingSCSS";

.small-link {
  cursor: pointer;
  text-decoration: underline;
}

.footer-card-content {
  display: flex;
  flex-flow: row nowrap;
}

.credit-card-img {
  width: 57px;
}

.container-with-actions {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
}
</style>
