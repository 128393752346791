<template>
  <footer class="footer-responsive align-center">
    <SignUp v-if="!isInternational" />
    <div class="row-1">
      <nav
        class="wrapper grid-container padding-bottom-l"
        :class="{ 'international': isInternational }"
        aria-label="Primary footer section"
      >
        <FooterLinks />
        <StayConnected />
      </nav>
    </div>
    <div class="row-2">
      <nav
        class="grid-container padding-top-m padding-bottom-m"
        aria-label="Secondary footer section"
      >
        <PrivacyLinks :links="legalLinks" />
        <Copyright />
        <Tagline />
      </nav>
    </div>
    <SSRFooterLinksRemover />
  </footer>
</template>

<script setup>
/* istanbul ignore file */
import { computed } from 'vue';
import { useStore } from 'vuex';
import FooterLinks from './FooterLinks.common';
import StayConnected from './StayConnected.bcom';
import Tagline from './Tagline.bcom';
import PrivacyLinks from './PrivacyLinks.common';
import Copyright from './Copyright.common';
import SignUp from './SignUp.bcom';
import { GET_LEGAL_LINKS } from '../../types/getters';
import SSRFooterLinksRemover from './SSRFooterLinksRemover';

const store = useStore();
const isInternational = computed(() => store.state.pageData.navigation.context.isInternational);
const legalLinks = computed(() => store.getters[`footerStore/${GET_LEGAL_LINKS}`]);
</script>

<style lang="scss" brand="bcom" scoped>
@import "../../scss/variables.bcom.scss";
@import "../../scss/mixins.bcom.scss";

.footer-responsive {
  .row-1 {
    background-color: $background-color;

    .wrapper {
      @include padding-top-m;
    }

    @include breakpoint(large up) {
      .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: map-get($grid-margin-gutters, 'medium');
        @include padding-top-l;

        :deep() {
          .footer-links-group {
            flex-grow: 1;
            flex-basis: 0;
          }
        }

        &.international {
          gap: map-get($grid-margin-gutters, 'large');
        }
      }
    }

    @include breakpoint(mlarge up) {
      .wrapper {
        gap: map-get($grid-margin-gutters, 'large');

        &.international {
          :deep() {
            .footer-links-group {
              flex-grow: 1;
              flex-basis: 0;

              label {
                text-transform: unset;
              }
            }
          }
        }
      }
    }
  }
  .row-2 {
    background-color: $white;
  }
}
</style>
