<template>
  <section id="link-rail">
    <div class="grid-container grid-x align-middle align-justify">
      <DefaultLinks v-if="isDomestic" />
      <InternationalLinks v-else />
    </div>
  </section>
</template>

<script setup>
/* istanbul ignore file */
import { computed } from 'vue';
import { useStore } from 'vuex';
import DefaultLinks from './DefaultLinks.bcom';
import InternationalLinks from './InternationalLinks.bcom';

const store = useStore();
const isDomestic = computed(() => store.state.pageData.navigation.context.isDomestic);
</script>

<style lang="scss" scoped brand="bcom">
#link-rail {
  z-index: 10;
  position: relative;
  width: 100%;
  border-bottom: 1px solid $gray-4-color;
}
</style>
