<template>
  <section class="grid-x align-center">
    <!-- eslint-disable vue/no-v-html -->
    <div class="sign-up-promo grid-x">
      <i class="email" />
      <div
        v-if="signupData.emailSignUp"
        class="signup-link link-upper-heavy"
        v-html="signupData.emailSignUp"
      />
    </div>
    <div class="shipping-promo grid-x">
      <div
        v-if="signupData.freeShipping"
        class="shipping grid-x heavy"
        v-html="signupData.freeShipping"
      />
      <div class="exclusions">
        <div
          v-if="signupData.loyallist"
          v-html="signupData.loyallist"
        />
        <div
          v-if="signupData.infoExclusions"
          class="exclusion-link"
          v-html="signupData.infoExclusions"
        />
      </div>
    </div>
    <!-- eslint-enable -->
  </section>
</template>

<script setup>
/* istanbul ignore file */
import { computed } from 'vue';
import { useStore } from 'vuex';

import { GET_SIGNUP_DATA } from '../../types/getters';

const store = useStore();
const signupData = computed(() => store.getters[`footerStore/${GET_SIGNUP_DATA}`]);
</script>

<style lang="scss" brand="bcom" scoped>
@import "../../scss/mixins.bcom.scss";

section {
  background: $background-color;
  align-items: center;
  flex-direction: column;
  height: 290px;
  border-top: 1px solid $gray-3-color;
  border-bottom: 1px solid $gray-3-color;
}

.shipping-promo {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shipping {
  align-items: center;
}

.exclusions {
  @include margin-top-xxs;
  align-items: center;
}

.exclusion-link {
  @include margin-top-xxs;
  text-decoration: underline;
  @include body(regular);
  cursor: pointer;
}

.sign-up-promo {
  @include padding-bottom-s;
  @include margin-bottom-s;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    width: 130px;
    height: 1px;
    background-color: $gray-3-color;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -65px;
  }
}

.signup-link {
  margin-top: 8px;

  :deep() a {
    text-decoration: none;
  }
}

.signup-link, .signup, .shipping, .exclusions {
  display: flex;
  flex-direction: column;
}

@include breakpoint(medium up) {
  section {
    flex-direction: row;
    height: 142px;
  }

  .shipping-promo {
    align-items: flex-start;
    border-left: 1px solid $gray-3-color;
    height: 94px;
    @include padding-left-s;
  }

  .sign-up-promo {
    @include margin-right-s;
    @include padding-bottom-z;
    @include margin-bottom-z;
    flex-direction: row;

    &:after {
      display: none;
    }
  }

  .signup-link {
    margin-top: 0;
    @include margin-left-xxs;
  }

  .signup-link, .shipping, .exclusions {
    align-items: flex-start;
  }

  .exclusions, .exclusion-link {
    margin-top: 4px !important;
  }
}

@include breakpoint(large up) {
  section {
    height: 92px;
  }

  .signup-link, .shipping, .exclusions {
    flex-direction: row;
  }

  .signup-link {
    @include menu-link-upper-heavy;
  }

  .shipping-promo {
    height: 44px;
  }

  .exclusion-link {
    @include margin-top-z;
    @include margin-left-xxs;
  }
}

@include breakpoint(mlarge up) {
  section {
    height: 72px;
  }
  .shipping-promo {
    flex-direction: row;
    align-items: center;
  }

  .exclusions {
    @include margin-top-z;
  }
}
</style>
