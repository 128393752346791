<template>
  <section
    v-if="!experiments.disableMediaRail"
    id="media-rail"
    class="media-rail"
  >
    <Slideshow
      v-if="headerData && items.length"
      :list="items"
      :show-indicator="false"
      @slideshowChangeClick="tagPromoBanner"
    >
      <template #item="slotProps">
        <div class="content-wrapper grid-x align-middle align-center">
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="content heavy-sm white-color"
            :data-index="slotProps.index"
            v-html="slotProps.data.text"
          />
          <!-- eslint-enable -->
        </div>
      </template>
    </Slideshow>
    <Skeleton
      v-else-if="!headerData"
      :height="skeletonHeight"
    />
  </section>
</template>

<script setup>
/* istanbul ignore file */
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import Skeleton from '@atomic-ui/skeleton';

import Slideshow from '../common/Slideshow';
import { GET_HEADER_DATA, GET_IS_MOBILE_VIEW } from '../../types/getters';
import { tagPromoBanner } from '../../features/analytics/promoBannerTags.bcom';
import dispatchNavigationAdBannerRendered from '../../utils/dispatchNavigationAdBannerRendered';
import useExperiments from '../../composables/useExperiments';

const store = useStore();
const experiments = useExperiments();

const headerData = computed(() => store.getters[`headerStore/${GET_HEADER_DATA}`]);

const items = computed(() => headerData.value?.topNavigation || []);
const skeletonHeight = computed(() => (store.getters[`navViewTypeStore/${GET_IS_MOBILE_VIEW}`] ? '72px' : '40px'),
); // eslint-disable-line

watch(headerData, (value) => {
  if (value && !value.topNavigation?.length) {
    dispatchNavigationAdBannerRendered();
  }
});
</script>

<style lang="scss" scoped brand="bcom">
:deep(a) {
  // @include menu-link-behavior; // TODO
  color: $white;
}

:deep(.slideshow-item) {
  margin: auto 0;
}

.media-rail {
  background-color: $black;
  color: $white;
}

.content-wrapper {
  padding: 12px 86px 12px 52px;
}

.content {
  text-align: center;
}

@include breakpoint(large up) {
  .content-wrapper {
    padding: 12px;
  }
}
</style>
