<template>
  <LinkRailMenuItem>
    <template #default="{ opened, toggle }">
      <button
        ref="$activator"
        class="link-rail-item small store-dropdown-title"
        data-testid="store-dropdown-title"
        aria-describedby="sr-only"
        :aria-expanded="opened"
        @click="handleActivatorClick"
        @keyup.enter="toggle"
        @mouseenter="fireAnalytics"
      >
        <div
          v-if="opened"
          class="store-dropdown-title-extension"
        />
        {{ storeName }}
      </button>
      <span
        id="sr-only"
        class="visibility-hidden"
      >store information dropdown collapsed button, in order to click this button, press Enter.</span>
    </template>

    <template #content="{ opened }">
      <StoreInfo
        v-if="opened"
        :is-opened="opened"
      >
        <template #store-name>
          <Link
            class="medium"
            :class="{
              hidden: !isStoreSelected && isMobile,
              'fixed-height': isMobile,
              'store-link': !isStoreSelected,
            }"
            :href="storeLocationLink"
          >
            {{ storeName }}
          </Link>
        </template>

        <template #default="{analytics}">
          <Link
            v-if="isBcom"
            class="link-body margin-top-xs block"
            :is-open-new-tab="true"
            :href="Links.store.bookAppointment.url"
            @click="analytics"
          >
            {{ Links.store.bookAppointment.text }}
          </Link>
        </template>
      </StoreInfo>
    </template>
  </LinkRailMenuItem>
</template>

<script setup>
/* istanbul ignore file */

import {
  computed, defineAsyncComponent, ref,
} from 'vue';
import { useStore } from 'vuex';
import { Links } from 'config';
import {
  GET_STORE_NAME, IS_STORE_SELECTED, GET_IS_MOBILE_VIEW, GET_ZIP_CODE,
} from '../../types/getters';
import LinkRailMenuItem from './LinkRailMenuItem.common';
import Link from '../common/Link';
import { getStoreLocationLink } from '../../utils/commonStoreLocationUtil';
import { fireLinkTag } from '../../features/analytics/tagManager';
import { useMobileNav } from '../../composables/useMobileNav';

const StoreInfo = defineAsyncComponent(() => import(/* webpackChunkName: "StoreInfo" */ '../header-responsive/StoreInfo.common'));

const store = useStore();
const { isBcom } = store.state.envProps;
const $activator = ref();
const { isMenuOpened } = useMobileNav();
const isMobile = computed(() => store.getters[`navViewTypeStore/${GET_IS_MOBILE_VIEW}`]);
const isStoreSelected = computed(() => store.getters[`navPreferredStore/${IS_STORE_SELECTED}`]);
const storeName = computed(() => store.getters[`navPreferredStore/${GET_STORE_NAME}`]);
const storeInfoZip = computed(() => store.getters[`navPreferredStore/${GET_ZIP_CODE}`]);
const storeLocationLink = computed(() => getStoreLocationLink(storeName.value, storeInfoZip.value));
// To test No Store Selected state remove one logical not operator

const fireAnalytics = () => {
  const payload = {
    event_name: 'store header change',
    link_name: 'store header change hover',
  };
  fireLinkTag(payload);
};

function handleActivatorClick() {
  if (isMenuOpened.value) {
    setTimeout(() => {
      $activator.value.dispatchEvent(new MouseEvent('mouseenter', { bubbles: true }));
    });
  }
}
</script>

<style scoped lang="scss" brand="bcom">
@import "../../scss/classes.bcom.scss";
.store-dropdown-title {
  position: relative;
  &-extension {
    position: absolute;
    top: 10px;
    right: 0;
    width: 100px;
    height: 40px;
  }
}

.store-locator-dropdown {
  min-width: 250px;
}

.block {
  display: block;
}

.padding {
  padding: 0 12px;
}

.link-body:hover {
  opacity: 1;
}
</style>
