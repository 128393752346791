<template>
  <div
    v-if="list.length"
    ref="vSlideshow"
    class="v-slideshow"
    :class="[wrapClass, { 'is-focus': isFocus }]"
  >
    <div
      class="v-slideshow-content"
      @mouseenter="scrollMouseenter"
      @mouseleave="scrollMouseleave"
      @focus.capture="onSlideshowFocus"
      @blur.capture="onSlideshowBlur"
    >
      <div
        ref="v-slideshow-wrapper"
        class="v-slideshow-wrapper grid-y nowrap"
      >
        <div class="v-button-wrapper prev">
          <button
            class="v-slideshow-button icon-24x24"
            aria-label="Go to previous slide"
            aria-controls="media-rail-slideshow"
            tabindex="0"
            @focus="onFocus"
            @blur="onBlur"
            @click.prevent="leftChevronClick"
            @keyup.enter="leftChevronClick"
          >
            <span
              focusable="false"
              aria-hidden="true"
              class="icon chevron-left-arrow"
            />
          </button>
        </div>
        <div
          ref="vScroller"
          :style="scrollerStyle()"
          class="v-scroller"
          @touchstart.passive="scrollerTouchStart"
          @touchmove.passive="scrollerTouchMove"
          @touchend.passive="scrollerTouchEnd"
        >
          <ul
            id="media-rail-slideshow"
            ref="slideshowList"
            class="grid-x v-slideshow nowrap"
            aria-label="slideshow"
            role="list"
          >
            <li
              key="last"
              class="cell small-12 slideshow-item"
              role="listitem"
              :class="prevIndex === 0 ? 'active' : ''"
            >
              <slot
                name="item"
                :data="list[list.length - 1]"
                :index="list.length - 1"
              />
            </li>
            <li
              v-for="(item, index) in list"
              :key="index"
              class="cell small-12 slideshow-item"
              role="listitem"
              :tabindex="(currentPage === (index + 1)) || (prevIndex === (index + 1)) ? 0 : -1"
              :class="(currentPage === (index + 1)) || (prevIndex === (index + 1)) ? 'active' : ''"
            >
              <slot
                name="item"
                :data="item"
                :index="index"
              />
            </li>
            <li
              key="first"
              class="cell small-12 slideshow-item"
              role="listitem"
              :class="prevIndex === (numberOfPages + 1) ? 'active' : ''"
            >
              <slot
                name="item"
                :data="list[0]"
                :index="0"
              />
            </li>
          </ul>
        </div>
        <div class="v-button-wrapper next">
          <button
            class="v-slideshow-button icon-24x24"
            aria-label="Go to next slide"
            aria-controls="media-rail-slideshow"
            tabindex="0"
            @focus="onFocus"
            @blur="onBlur"
            @click.prevent="rightChevronClick"
            @keyup.enter="rightChevronClick"
          >
            <span
              focusable="false"
              aria-hidden="true"
              class="icon chevron-right-arrow"
            />
          </button>
        </div>
        <div class="control-button-wrapper">
          <button
            class="control-button icon-24x24"
            :aria-label="isPlaying?'Pause':'Play'"
            @click="togglePlayButton"
          >
            <span
              v-if="isPlaying"
              class="pause-control"
            >
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5 6v-.5h-3v13h3V6Zm8 0v-.5h-3v13h3V6Z"
                  fill="#fff"
                  stroke="#fff"
                  stroke-linecap="round"
                />
              </svg>
            </span>
            <span
              v-else
              class="play-control"
            >
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.3 4.6 7.5 4v16l.8-.6 10-7 .6-.4-.6-.4-10-7Z"
                  fill="#fff"
                  stroke="#fff"
                  stroke-linecap="round"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div
        v-if="showIndicator"
        class="v-dot-indicator-wrapper small-margin-top-xs medium-margin-top-m "
      >
        <ul class="grid-x align-center">
          <li
            v-for="index in numberOfPages"
            :id="index"
            :key="index"
            :class="{ active: index === currentPage }"
            @click="change(index)"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
/* istanbul ignore file */
import {
  ref, computed, onMounted, onBeforeUpdate, nextTick,
} from 'vue';
import { useStore } from 'vuex';

import { GET_IS_MOBILE_VIEW } from '../../types/getters';
import { fireLinkTag } from '../../features/analytics/tagManager';
import dispatchNavigationAdBannerRendered from '../../utils/dispatchNavigationAdBannerRendered';

const store = useStore();
const isMobile = computed(() => store.getters[`navViewTypeStore/${GET_IS_MOBILE_VIEW}`]);

const props = defineProps({ // eslint-disable-line no-undef
  list: {
    type: Array,
    required: true,
    default: () => [],
  },
  autoplayMode: {
    type: String,
    default: 'auto',
    validator: (val) => ['auto', 'manual'].includes(val),
  },
  backToFirst: {
    type: Boolean,
    default: false,
  },
  showIndicator: {
    type: Boolean,
    default: true,
  },
  autoplayInterval: {
    type: String,
    default: '5000',
    validator(value) {
      return value >= 800;
    },
  },
  thresholdLimit: {
    type: Number,
    default: 0.5,
    validator(value) {
      return value >= 0 && value <= 1;
    },
  },
  wrapClass: {
    type: String,
    default: '',
  },
});

const emits = defineEmits(['slideshow-change']); // eslint-disable-line no-undef
const numberOfPages = ref(1);
const itemsLength = ref(3);
const scrollerWidth = ref(null);
const childWidth = ref(0);
const currentPage = ref(1);
const scrollerX = ref(0);
const timer = ref(null);
const noTransition = ref(false);
const sliding = ref(false);
const isFocus = ref(false);
const prevIndex = ref(-1);
const isPlaying = ref(true);
const startX = ref(null);
const touchstartX = ref(null);
const touchendX = ref(null);
const touchmoveX = ref(null);
const vScroller = ref(null);
const vSlideshow = ref(null);
const slideshowList = ref(null);

const onFocus = () => {
  isFocus.value = true;
};
const onBlur = () => {
  isFocus.value = false;
};

const scrollerStyle = () => {
  if (scrollerWidth.value !== null) {
    return `
            width: ${scrollerWidth.value}px;
            transform: translateX(${scrollerX.value}px);
            transition-duration: ${noTransition.value ? 0 : 300}ms;
          `;
  }
  return null;
};
const scrollerTouchStart = (event) => {
  startX.value = scrollerX.value;
  touchstartX.value = event.changedTouches[0].screenX;
};
const slotsItems = () => {
  if (vScroller.value) {
    return [
      ...vScroller.value.getElementsByClassName('slideshow-item'),
    ];
  }
  return [];
};
const checkImgLoaded = (index) => {
  const nextItem = slotsItems()[index];
  if (!nextItem) {
    return true;
  }
  const imgs = nextItem.getElementsByTagName('img');
  let completed = true;
  if (imgs && imgs.length) {
    [...imgs].forEach((img) => {
      if (!img.complete) completed = false;
    });
  }
  return completed;
};
const scrollerPosition = (activePage) => -(
  childWidth.value
      * (activePage !== undefined ? activePage : currentPage.value)
);
const move = (flag) => {
  if (!checkImgLoaded(currentPage.value + flag)) return false;
  if (sliding.value) return false;
  sliding.value = true;
  if (
    (flag < 0 && currentPage.value > 1)
    || (flag > 0 && currentPage.value < numberOfPages.value)
  ) {
    prevIndex.value = currentPage.value;
    currentPage.value += flag;
    scrollerX.value = scrollerPosition();
    emits('slideshow-change', currentPage.value);
    setTimeout(() => {
      sliding.value = false;
      prevIndex.value = -1;
    }, 300);
  } else {
    prevIndex.value = currentPage.value + flag;
    scrollerX.value = scrollerPosition(currentPage.value + flag);
    emits('slideshow-change', currentPage.value);
    setTimeout(() => {
      currentPage.value = flag > 0 ? 1 : numberOfPages.value;
      noTransition.value = true;
      scrollerX.value = scrollerPosition();
      prevIndex.value = -1;
      setTimeout(() => {
        noTransition.value = false;
        sliding.value = false;
      }, 50);
    }, 300);
  }
  return true;
};

const leftChevronClick = () => {
  const payload = {
    event_name: 'promo banner left',
    link_name: 'promo banner left',
  };
  fireLinkTag(payload);
  move(-1);
};

const rightChevronClick = () => {
  const payload = {
    event_name: 'promo banner right',
    link_name: 'promo banner right',
  };
  fireLinkTag(payload);
  move(1);
};

const scrollerTouchMove = (event) => {
  touchmoveX.value = event.changedTouches[0].screenX;
  const distance = touchstartX.value - touchmoveX.value;
  scrollerX.value = startX.value - distance;
  prevIndex.value = currentPage.value + (distance > 0 ? 1 : -1);
};
const handleSwipe = () => {
  const threshold = 1 - props.thresholdLimit;
  const distance = Math.abs(touchstartX.value - touchendX.value);
  const hasPassedThreshold = distance > childWidth.value * threshold;
  if (hasPassedThreshold) {
    if (touchendX.value < touchstartX.value && !move(1)) {
      scrollerX.value = startX.value;
    } else if (touchendX.value > touchstartX.value && !move(-1)) {
      scrollerX.value = startX.value;
    }
  } else {
    scrollerX.value = startX.value;
    prevIndex.value = -1;
  }
};
const scrollerTouchEnd = (event) => {
  touchendX.value = event.changedTouches[0].screenX;
  handleSwipe();
};
const change = (index) => {
  if (!checkImgLoaded(index)) return;
  currentPage.value = index;
  scrollerX.value = scrollerPosition();
};
const reset = () => {
  scrollerWidth.value = null;
  slotsItems().forEach((item) => {
    item.style.width = null;
  });
};
const startAutoplay = () => {
  timer.value = setInterval(() => {
    move(1);
  }, props.autoplayInterval);
};
const stopAutoplay = () => {
  if (!timer.value) return;
  clearInterval(timer.value);
  timer.value = null;
};
const doSizing = () => {
  stopAutoplay();
  numberOfPages.value = props.list.length;
  itemsLength.value = numberOfPages.value + 2;
  childWidth.value = vSlideshow.value?.getBoundingClientRect().width;
  slotsItems().forEach((item) => {
    item.style.width = `${childWidth.value}px`;
  });
  scrollerWidth.value = itemsLength.value * childWidth.value;
  scrollerX.value = scrollerPosition();
  if (slideshowList.value) slideshowList.value.classList.remove('nowrap');
  if (props.autoplayMode === 'auto') startAutoplay();
};
const init = () => {
  reset();
  nextTick(doSizing);
};
const eventHandler = () => {
  const rOInstance = new ResizeObserver(init);
  rOInstance.observe(vSlideshow.value);
};
const scrollMouseenter = () => {
  if (isMobile.value) return;
  isFocus.value = true;
  if (props.autoplayMode === 'auto') {
    stopAutoplay();
  } else if (props.autoplayMode === 'manual') {
    startAutoplay();
  }
};
const scrollMouseleave = () => {
  if (isMobile.value) return;
  isFocus.value = false;
  if (props.autoplayMode === 'auto' && !timer.value && isPlaying.value) {
    startAutoplay();
  } else if (props.autoplayMode === 'manual') {
    stopAutoplay();
    if (props.backToFirst) change(1);
  }
};
const togglePlayButton = () => {
  isPlaying.value = !isPlaying.value;
  if (isFocus.value && isPlaying.value) {
    return move(1);
  }
  if (isFocus.value) return null;
  if (isPlaying.value) return startAutoplay();
  return stopAutoplay();
};

const onSlideshowFocus = () => {
  isFocus.value = true;
  if (timer.value) stopAutoplay();
};
const onSlideshowBlur = () => {
  isFocus.value = false;
  if (!timer.value && isPlaying.value) startAutoplay();
};

onMounted(() => {
  if (props.list.length) eventHandler();

  dispatchNavigationAdBannerRendered();
});

onBeforeUpdate(() => {
  if (numberOfPages.value !== props.list.length) init();
});
</script>

  <style lang="scss" scoped>
  @import "../../scss/classes.scss";

  $transition: transform 300ms cubic-bezier(0.1, 0.57, 0.1, 1);

  .v-slideshow-content {
    position: relative;
    overflow: hidden;
  }

  .v-button-wrapper {
    position: absolute;
    z-index: 2;
    height: 100%;
    background-color: $black;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    &.prev {
      left: 0;
      padding-left: 20px;
    }
    &.next {
      right: rem-calc(44);
    }
  }

  .v-slideshow-button {
    z-index: 4;
    border: 0;
    padding: 0;
    cursor: pointer;
    min-height: 0;
    mix-blend-mode: normal;
    .icon {
      display: block;
      margin: 0 auto;
    }
  }

  .slideshow-item {
    visibility: hidden;
    &.active {
      visibility: visible;
    }
  }

  :deep(.content-wrapper) {
    max-width: 1480px;
  }

  .v-slideshow-wrapper {
    overflow: hidden;
    position: relative;
    .v-scroller {
      -webkit-transition: $transition;
      -moz-transition: $transition;
      -ms-transition: $transition;
      -o-transition: $transition;
      transition: $transition;
    }
  }

  .v-dot-indicator-wrapper {
    width: 100%;
    li {
      display: inline-block;
      border-radius: 50%;
      padding: rem-calc(2);
      margin-right: rem-calc(8);
      cursor: pointer;
      background-color: $gray-2-color;
      border: rem-calc(1) solid $gray-2-color;
      &.active {
        background-color: $black;
        border: rem-calc(1) solid $black;
      }
    }
  }
  .control-button-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding-right: 20px;
    background-color: $black;
    display: flex;
    align-items: center;
  }
  .control-button {
    z-index: 4;
    cursor: pointer;
  }

  @include breakpoint(large up){
    .v-slideshow-content{
        max-width: 1480px;
        margin-left: auto;
        margin-right: auto;
    }
  }

  </style>
  <style lang="scss" brand="mcom" scoped>
  @import "@csgIcons/_chevron-medium-left-black.scss";
  @import "@csgIcons/_chevron-medium-right-black.scss";

  .chevron-left-arrow{
    @extend .chevron-medium-left-black;
  }
  .chevron-right-arrow{
    @extend .chevron-medium-right-black;
  }
  </style>
  <style lang="scss" brand="bcom" scoped>
  @import "@csgIcons/_chevron-small-left-white.scss";
  @import "@csgIcons/_chevron-small-right-white.scss";
  .chevron-left-arrow{
    @extend .chevron-small-left-white;
  }
  .chevron-right-arrow{
    @extend .chevron-small-right-white;
  }
  </style>
