<template>
  <LinkRailMenuItem>
    <button
      class="link-rail-item align-middle menu-link-sm"
      @click.prevent="openInternationalSlideout"
    >
      <Flag
        width="16px"
        height="16px"
        class="rounded margin-right-xxs"
      />
      {{ currencyCode }}
    </button>
  </LinkRailMenuItem>
</template>

<script setup>
/* istanbul ignore file */
import { computed } from 'vue';
import { useStore } from 'vuex';

import useInternationalSlideout from '../../composables/features/useInternationalSlideout';
import Flag from '../common/Flag.common';
import LinkRailMenuItem from './LinkRailMenuItem.common';

const store = useStore();
const currencyCode = computed(() => store.state.pageData.navigation.context.currencyCode);
const { openInternationalSlideout } = useInternationalSlideout();
</script>

<style lang="scss" scoped brand="bcom">
@import "../../scss/classes.bcom.scss";
</style>
