<template>
  <div class="full-width grid-x align-middle align-justify">
    <ul class="links-wrap grid-x align-middle">
      <li>
        <Link
          class="link-rail-item small"
          :href="Links.store.scheduling.url"
          :tracking="Links.store.scheduling.tracking"
        >
          Shopping Services
        </Link>
      </li>
    </ul>
    <LinkRailMenu v-if="mounted && internationalContextEnabled">
      <InternationalContextLink />
    </LinkRailMenu>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useMounted } from '@vueuse/core';

import Links from '../../constants/Links';
import Link from '../common/Link';
import LinkRailMenu from './LinkRailMenu.common';
import InternationalContextLink from './InternationalContextLink.bcom';
import useKillswitches from '../../composables/useKillswitches';

const mounted = useMounted();
const killswitches = useKillswitches();
const internationalContextEnabled = computed(() => killswitches.value.internationalContextEnabled);
</script>

<style lang="scss" scoped brand="bcom">
@import "../../scss/classes.bcom.scss";
</style>
