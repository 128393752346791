<template>
  <div class="grid-x align-center margin-top-xs">
    <Picture
      :image-url="taglineAssetSrc"
      alt="Like No Other Store in the World"
      :using-scene7="false"
    />
  </div>
</template>

<script setup>
import Picture from '@atomic-ui/picture';
import getAssetImage from '../../utils/getAssetImage';

const taglineAssetSrc = getAssetImage('tagline.svg');
</script>
