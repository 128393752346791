import Links from '../Links';

function staticData(rootState) {
  const { isDomestic } = rootState.pageData.navigation.context;

  if (isDomestic) {
    return [
      { text: 'bRegistry', ...Links.homepage.registry, isRegistrySection: true },
      { text: 'Stores & events', ...Links.store.locator },
      { text: 'Shopping services', ...Links.store.scheduling },
      { text: 'Promotions', ...Links.promo.promotions },
      { internationalLink: true },
    ];
  }

  return [
    { text: 'Stores & events', ...Links.store.locator },
    { text: 'Shopping services', ...Links.store.scheduling },
    { internationalLink: true },
  ];
}

// TODO remove once we migrate to aem
export default staticData;
