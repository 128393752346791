<template>
  <div class="grid-container grid-x align-middle align-justify padding-top-xs padding-bottom-xxs">
    <Logo />
    <Search />
    <WishList
      v-if="!experiments.accountDropdownEnabled"
      class="margin-right-s"
    />
    <BagIcon />
  </div>
</template>

<script setup>
import Search from '../search/Search.common';
import WishList from '../common/WishList.common';
import Logo from '../common/Logo.common';
import BagIcon from '../common/BagIcon.common';
import useExperiments from '../../composables/useExperiments';

const experiments = useExperiments();
</script>
