<template>
  <div
    class="grid-y stay-connected"
    :class="{
      'international': !isDomestic,
    }"
  >
    <CreditCard
      v-if="isDomestic"
      class="footer-section"
    />
    <SocialLinks class="footer-section">
      Follow Us
    </SocialLinks>
    <AppSection
      class="footer-section footer-apps"
      title="Bloomingdale's"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CreditCard from './CreditCard.bcom';
import SocialLinks from './SocialLinks.common';
import AppSection from './AppSection.common';

const store = useStore();
const isDomestic = computed(() => store.state.pageData.navigation.context.isDomestic);
</script>

<style lang="scss" brand="bcom" scoped>
@import "../../scss/mixins.bcom.scss";

.stay-connected {
  flex-basis: 0;

  @include breakpoint(large up) {
    flex-basis: 317px;
  }

  @include breakpoint(mlarge up) {
    flex-basis: 464px;
  }

  &.international {
    flex-grow: 1;
    flex-basis: 0;
  }
}

.footer-section {
  position: relative;
  @include padding-top-m;
  @include padding-bottom-m;

  &::before {
    position: absolute;
    top: 0;
    left: -20px;
    width: 100vw;
    height: 1px;
    content: '';
    background-color: $gray-3-color;
  }

  &:last-child {
    @include padding-bottom-z;
    border-bottom: none;
  }

  @include breakpoint(large up) {
    &::before {
      left: 0;
      width: 100%;
    }

    &:first-child {
      @include padding-top-z;

      &::before {
        display: none;
      }
    }
  }
}

@include breakpoint(large up) {
  .footer-apps {
    display: none;
  }
}
</style>
